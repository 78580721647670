.limitera {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.CheckBoxAdmin {
    cursor: pointer;
}

.containera {
    width: auto;
    height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
}

.titlea {
    width: 100%;
    display: block;
    font-family: "Times New Roman", Times, serif;
    font-size: 30px;
    color: #555555;
    background: #EBECF0;
    text-align: center;
    padding-bottom: 20px;
}

.img-wrapa {
    width: auto;
    height: auto;
    max-width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    Justify-content: center;
    background: #EBECF0;
}

.wrap-logina {
    float: center;
    width: 95%;
    height: 80%;
    background: #e1e1e1;
    border-radius: 10px;
    position: relative;
}

.container-logout-btna {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    padding-bottom: 10px;
}

.wrap-logout-btna {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border-radius: 10px;
    font-family: "Times New Roman", Times, serif;
    font-size: auto;
    font-style: bold;
    line-height: 1.2;
}

.list-containera {
    background: #EBECF0;
    text-align: right;
    margin: 0;
    padding: 0;
    }

.paddinga {
    padding: 0 20px;
}

.paddingb {
    padding: 0 20px;
    padding-bottom: 5px;
}

.paddingl {
    padding-right: 20px;
}

.list-containera li {
    display: inline-block;
    width: 100px;
    text-align: center;
}

.list-containers {
    text-align: right;
    margin: 0;
    padding: 0;
}

.list-containers li {
    display: inline-block;
    width: auto;
    text-align: center;
}

.list-containeru {
    background: #EBECF0;
    text-align: right;
    margin: 0;
    padding: 0;
}

.link-forget {
    color: #555555;
}

.list-containeru li {
    display: inline-block;
    width: 100%;
    text-align: right;
}

/*AdminPageReg CSS*/

.inf-title {
    width: 100%;
    display: block;
    font-size: 25px;
    font-style: bold;
    color: #555555;
    text-transform: uppercase;
    text-align: center;
}

.title2 {
    width: 100%;
    display: block;
    font-size: 23px;
    color: #555555;
    text-align: center;
}

.ititle {
    width: 100%;
    display: block;
    font-size: 20px;
    color: #555555;
    text-align: center;
}

.ititleu {
    width: 100%;
    display: block;
    font-size: 20px;
    color: #555555;
    text-align: left;
}

.ititleu2 {
    width: 100%;
    display: block;
    font-size: 30px;
    color: #555555;
    text-align: left;
}

.list-input {
    background: inherit;
    text-align: center;
    margin: 0;
    padding: 0;
}

.list-input li {
    margin: 0;
    padding: 0;
    width: 50%;
    display: inline-block;
    text-align: left;
}

textarea {
    outline: none;
    border: none;
}

label {
    display: block;
    margin: 0;
}

.txt1 {
    padding: 20px;
    font-family: "Times New Roman", Times, serif;
    font-size: auto;
    line-height: 1.5;
}

.wrap-inputa {
    width: 80%;
    height: 40px;
    position: relative;
    background-color: #f7f7f7;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-left: 20px;
}

.wrap-email-input {
    width: 40%;
    height: 40px;
    position: relative;
    background-color: #f7f7f7;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-left: 20px;
}

.inputa {
    font-family: "Times New Roman", Times, serif;
    line-height: 1.2;
    font-size: 18px;
    display: block;
    width: 100%;
    background: transparent;
    height: 40px;
    padding: 0 10px;
    outline: none;
    border: none;
}

.container-login-form-btna {
    width: 20%;
    padding: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.login-form-btna {
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 30px;
    background-color: #bebebe;
    border-radius: 10px;
    font-family: "Times New Roman", Times, serif;
    font-size: 15px;
    font-style: bold;
    line-height: 1.2;
}

/*Menu*/

.navbar {
    overflow: hidden;
    background-color: #bebebe;
}

.navbar a {
    float: left;
    font-size: 15px;
    color: black;
    background-color: inherit;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 15px;
    border: none;
    outline: none;
    color: black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.navbar a:hover, .dropdown:hover .dropbtn {
    background-color: gray;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    background-color: #f9f9f9;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: gray;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* For mobile phones: */

@media only screen and (max-width: 1369px) {
    .acontent-table table, thead, tbody, th, td, tr {
        display: block;
    }
    .acontent-table thead tr {
        display: none;
    }
    .acontent-table tr {
        border: 1px solid black;
        margin-bottom: 30px;
    }
    .acontent-table td {
        border: none;
        color: #555555;
        position: relative;
    }
    .acontent-table td:before {
        content: attr(data-label);
        color: black;
        padding: 10px;
        position: relative;
        text-align: left;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 1050px) {
    .dropdown-content {
        width: 100%;
    }
    .wrap-logina {
        width: 100%;
    }
    .dropdown-content a {
        width: 100%;
        text-align: center;
    }
    .dropdown {
        float: center;
        overflow: hidden;
        width: 100%;
    }
    .dropdown .dropbtn {
        position: relative;
        text-align: center;
        padding: 6px 6px;
        width: 100%;
    }
    .navbar a {
        width: 100%;
        padding: 6px 6px;
    }
    .paddingb {
        padding: 0 5px;
        padding-bottom: 10px;
    }
    .paddingl {
        padding-right: 5px;
    }
    .list-input li {
        display: block;
        width: 100%;
    }
    .wrap-inputa {
        width: auto;
        max-width: 100%;
        height: auto;
        position: relative;
        margin-right: 20px;
    }
    .wrap-email-input {
        width: auto;
        max-width: 100%;
        height: auto;
        position: relative;
        margin-right: 20px;
    }
    .icon-bar a {
        font-size: 15px;
        width: auto;
        max-width: 100%;
    }
    .titlea {
        font-size: 20px;
    }
    .container-login-form-btna {
        width: 40%;
    }
}

.atables {
    display: flex;
    justify-content: center;
}

.acontent-table {
    border-collapse: collapse;
    width: 100%;
}

.acontent-table td, .acontent-table th {
    padding: 5px 10px;
    border: 1px solid #beaaaa;
    text-align: center;
}

.acontent-table th {
    background-color: #bebebe;
    font-weight: normal;
}

.login-error {
    color: red;
    padding: 0px 20px 5px;
}